import "core-js/modules/es.array.push.js";
import { reactive, toRefs, computed, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { ElTabs, ElTabPane } from "element-plus";
import httpApi from "@/api/httpApi";
export default {
  components: {
    ElTabPane,
    ElTabs
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const {
      dispatch,
      commit,
      state
    } = useStore();
    const pageCaches = computed(() => state.pageCaches.map(p => p.name));
    const keepAliveMax = computed(() => state.keepAliveMax);
    const refData = reactive({
      activeName: "/embedPage/live"
    });
    const sessionId = computed(() => route.query.key || '');
    const imei = computed(() => route.query.imei || '');
    //跳转
    const handleClick = item => {
      router.push(item.props.name);
    };
    nextTick(async () => {
      if (!sessionId.value) {
        router.push('/error/404');
      } else {
        commit('setSessionId', sessionId.value);
        console.log("index.vue getUserInfoByKey " + sessionId.value);
        // dispatch('getUserInfo');
        const userInfoRes = await httpApi.getUserInfoByKey({
          appkey: `${sessionId.value}`
        });
        console.log(userInfoRes, 'userInfoRes');
        if (userInfoRes.code !== 0) {
          commit('cleanLoginInfo');
          return userInfoRes;
        }
        // 写入登录信息
        commit('setUserInfo', userInfoRes.data);
        if (userInfoRes.data.resourceIdList.length > 0) {
          commit('setPermList', userInfoRes.data.resourceIdList);
        }
        // state.userInfo && dispatch('getTreeData');
      }
      ;
      refData.activeName = `${route.path}?key=${sessionId.value}&imei=${imei.value}`;
    });
    return {
      ...toRefs(refData),
      handleClick,
      sessionId,
      pageCaches,
      keepAliveMax,
      imei
    };
  }
};