import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-cef170cc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "embedPage"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane");
  const _component_el_tabs = _resolveComponent("el-tabs");
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_tabs, {
    type: "border-card",
    modelValue: _ctx.activeName,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.activeName = $event),
    onTabClick: $setup.handleClick
  }, {
    default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
      label: "实时监控",
      name: `/embedPage/live?key=${$setup.sessionId}&mdtid=${$setup.imei}`
    }, null, 8, ["name"]), _createVNode(_component_el_tab_pane, {
      label: "轨迹回放",
      name: `/embedPage/trajectory?key=${$setup.sessionId}&mdtid=${$setup.imei}`
    }, null, 8, ["name"])]),
    _: 1
  }, 8, ["modelValue", "onTabClick"]), _createVNode(_component_router_view, null, {
    default: _withCtx(({
      Component,
      route
    }) => [(_openBlock(), _createBlock(_KeepAlive, null, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
      key: route.name
    }))], 1024))]),
    _: 1
  })]);
}